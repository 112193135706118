
<template>

  <MenuPrincipal :login="login"></MenuPrincipal>
  <div class="container-fluid" >
    <router-view/>
    <div >
      <div class="row footer">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="logo" >
            <img
                alt="Vue logo"
                src="logo_hrizontal_blanco.png"
                 >

          </div>
          <div style="margin-bottom: 25px">
            <a class="social-icons" href="#" target="_blank">
              <font-awesome-icon icon="fa-brands fa-facebook" size="2x" color="white"/>
            </a>
<!--            <a class="social-icons" href="#" target="_blank">
              <font-awesome-icon icon="fa-brands fa-twitter" size="2x" color="white"/>
            </a>-->
            <a class="social-icons" href="#" target="_blank">
              <font-awesome-icon icon="fa-brands fa-instagram" size="2x" color="white"/>
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <h2 class="p-4">Contáctenos:</h2>
          <div>
            <p>Teléfono: +34 0000000000</p>
            <p>Email: info@theracehorseexperience.com</p>
          </div>
        </div>
        <!-- <div class="col-12 col-sm-12 col-md-3 col-lg-3 ">
          <h2 style="padding-top: 25px"> Partners: </h2>
          <div class="logo" >
            <img
                alt="Vue logo"
                src="http://harasranchofatima.com/wp-content/uploads/2022/12/Logo.png"
            >

          </div>
        </div> -->
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <h4 class="p-4 ">Sitios de interés:</h4>
          <div>
            <ul class="list-group text-left" style="background-color: lightslategray">
            <li  class="list-group-item" ><router-link to="politicas" style="color: white;">Políticas de Privacidad</router-link> </li>
            <li  class="list-group-item" ><router-link to="terminos" style="color: white;">Términos y Condiciones</router-link></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row copyrigth ">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 padre justify-content-center padre">
          <div class="hijo">
            <p>Copyright © {{ year }}. Hecho por
               <a href="https://www.ecuaproducciones.com" style="color: #0d6efd" >ECUAPRODUCCIONES</a>
            </p>
          </div>

        </div>
      </div>
  </div>
  </div>
</template>
<script>
/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faFacebook,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons'


import MenuPrincipal from './components/MenuPrincipal.vue'
import store from "@/store";
/* add each imported icon to the library */
library.add(faTwitter,faFacebook,faInstagram)
export default {
  name:'app',
  data(){
  return {
    login: false,
    year: new Date().getFullYear()
  }
},

  components:{
    MenuPrincipal

  }, methods:{

  }

}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: 'centurygothic';
  src: url('~@/assets/fonts/centurygothic.ttf');

}
.texto-justificado{
  text-align: justify;
}
.footer{
  background-color: lightslategray;
  color: #FFFFFF;
  margin-top: 25px;
  padding: 0;
}
.social-icons{
  margin: 10px;
}
.padre {
  display: flex;
  align-items: center;
  padding-top: 15px;

}
.hijo {
   padding-right: 30px;
   padding-left: 150px;
}
.copyrigth{
  background-color: #2c3e50;
  color: #FFFFFF;
  padding: 0;
}
.list-group-item{
  background-color:  lightslategray;
  border: 0px;
  color: wite;           
  ;
}
/*Mobile version*/
@media screen and (max-width: 768px) {

  .footer{
    background-color: lightslategray;
    color: #FFFFFF;
    margin-top: 10px;
    padding: 0px;
  }
  .social-icons{
    margin: 15px;
  }
  .hijo {
    padding-right: 0px;
    padding-left: 15px;
  }
}
</style>
