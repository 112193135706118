<template>

      <div class="row movil">
        <div class="col-xs-12 col-md-6 col-lg-4" style="padding: 15px">
          <h2 class="card-title">QUIÉNES<span class="thr"> Somos</span></h2>
          <img class="img-fluid rounded-start d-block w-100" :src="photo1"  alt="..." style="max-width: 100%;max-height: 400px">
        </div>

        <div class="col-md-12 col-xs-12 col-lg-4">
          <div class="card-body">

            <p class="card-title  texto-justificado" v-html="content"></p>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="col col-xs-12 col-md-6 col-lg-4 bloque" >
            <img class="img-fluid rounded-start d-block w-100" :src="photo2"  alt="..." style="max-width: 100%;max-height: 400px">
          </div>
        </div>
      </div>
      <div class="row pc">
    <div class="col-xs-12 col-md-6 col-lg-4" style="padding: 15px">
      <img class="img-fluid rounded-start d-block w-100" :src="photo1"  alt="..." style="max-width: 100%;max-height: 400px">
    </div>
    <div class="col col-xs-12 col-md-6 col-lg-4 bloque" >
      <img class="img-fluid rounded-start d-block w-100" :src="photo2"  alt="..." style="max-width: 100%;max-height: 400px">
    </div>
    <div class="col-md-12 col-xs-12 col-lg-4">
      <div class="card-body">
        <h2 class="card-title">QUIENES<span class="thr"> Somos</span></h2>
        <p class="card-title  texto-justificado" v-html="content"></p>
      </div>
    </div>
  </div>

</template>

<script>
import LoremIpsum from 'vue-lorem-ipsum';
export default {
  name: "HistoriaCard",
  components:{
    LoremIpsum,

  },
  props:['tittle','content','photo1','photo2']

}
</script>

<style scoped>
.thr {
  font-family: 'centurygothic';
  color: #d80812;
  font-size: 60px;
}
.bloque{
  padding: 15px
}
.movil{
  display: none;
}
/*Mobile version*/
@media screen and (max-width: 768px) {
  .thr{
    font-size: 30px;
  }
  .bloque{
    padding: 0;
  }
  .movil{
    display: block;
    margin-top: 40px;
  }
.pc{
  display: none;
}

}
</style>
